<template>
  <div class="container home-warp">
    <img :src="require('@/assets/image/personbg.png')"  alt="" class="img1" />
    <div class="person-box d-f a-center">
      <img :src="require('@/assets/image/kqperson.png')"  @click="handleQuit"  alt="" />
      <span class="p-l10 f-s14">区域:{{userInfo.area ? userInfo.area.name : 'xx'}}</span>
      <span class="p-l16 f-s14"> 用户:{{userInfo.name}}</span>
  
    </div>
    <div class="home-content">
      <div class="content-top">
        <div class="bg-blur"></div>
        <div @click="handleRouterTo('fwxx')">
          <p>房屋总数</p>
          <p class="num">{{ formData.house || 0 }}</p>
        </div>
        <div @click="handleRouterTo('ryxx')">
          <p>人口总数</p>
          <p class="num">{{ formData.people || 0 }}</p>
        </div>
        <div @click="handleRouterTo('yhd')">
          <p>隐患点总数</p>
          <p class="num">{{ formData.hida || 0 }}</p>
        </div>
        <div class="m-t12"  @click="handleRouterTo('sjsb',1)">
          <p>上报事件数</p>
          <p class="num">{{ formData.event.report || 0 }}</p>
        </div>
        <div class="m-t12" @click="handleRouterTo('zfrw',1)">
          <p>本月已走访</p>
          <p class="num">
            {{ formData.interview || 0 }}
          </p>
        </div>
        <div class="m-t12" @click="handleRouterTo('pfrw')">
          <p>派发事件数</p>
          <p class="num">{{ formData.event.assign || 0 }}</p>
        </div>
      </div>
      <div class="content-bottom ">
        <div class="has-bbottom" @click="handleRouterTo('ryxx')">
          <img src="../../assets/image/home1.png" alt="" />
          <div class="f-s12 m-t8">人员信息</div>
        </div>
        <div class="has-bbottom" @click="handleRouterTo('fwxx')">
          <img src="../../assets/image/home2.png" alt="" />
          <div class="f-s12 m-t8">房屋信息</div>
        </div>
        <div class="has-bbottom" @click="handleRouterTo('yhd')">
          <img src="../../assets/image/home3.png" alt="" />
          <div class="f-s12 m-t8">隐患点</div>
        </div>
        <div class="has-bbottom" @click="handleRouterTo('sjsb')">
          <div class="icon-warp">
            <img src="../../assets/image/home4.png" alt="" />
            <img src="../../assets/image/home4-icon.png" class="icon" alt="">
          </div>
          <div class="f-s12 m-t8">事件上报</div>
        </div>
        <div class="has-bbottom" @click="handleRouterTo('zfrw')">
          <img src="../../assets/image/home5.png" alt="" />
          <div class="f-s12 m-t8">走访任务</div>
        </div>
        <div class="has-bbottom" @click="handleRouterTo('pfrw')">
          <div  class="icon-warp">
            <img src="../../assets/image/home6.png" alt="" />
            <img src="../../assets/image/home6-icon.png" class="icon" alt="">

          </div>
          <div class="f-s12 m-t8">派发任务</div>
        </div>
        <div @click="handleRouterTo('kqdk')">
          <div  class="icon-warp">
            <img src="../../assets/image/home7.png" alt="" />
            <img src="../../assets/image/home7-icon.png" class="icon" alt="">

          </div>
          <div class="f-s12 m-t8">考勤打卡</div>
        </div>
        <div></div>
      </div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="block">
          <p class="title-bd">登录</p>
          <van-field v-model="phone" label="手机号" placeholder="请输入" />
          <van-field v-model="pwd" label="密码" type="password" placeholder="请输入" />

          <div class="btn-warp">
            <van-button type="primary" size="small"   @click="handleLogin">确定</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import httpService from "@/plugins/http";
import { mapState } from "vuex";
import { Toast,Dialog } from 'vant';
import sha1 from "sha1";
export default {
  computed: {
    ...mapState(["token","userInfo"]),
  },
  data() {
    return {
      show:false,
      formData: {
        event: {}, //事件
      },
      getWechatTokenParams: {
        // 换取微信token请求的参数
        appId: 'wx698af04c6882e6d1',
        secret: 'a958d36e26bfd681d52ad99be539fe6c',
        // appId: "wxedb6aba585670f01",
        // secret: "e41818443e91c7092774e6bd3fcb4b9e",
        code: ''
      },
      phone:"", // 手机号
      pwd:"", // 密码
      appId:"", // 用户appid
    };
  },
  mounted() {
    this.wxinit()
  },
  methods: {
     async wxinit() {
      if(this.token) {
        this.getInit()
      } else {
        let wx_code = this.getUrlParam("code"); // 截取url中的code
        if (!wx_code) {
          let url = encodeURIComponent(window.location.href);
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.getWechatTokenParams.appId}&redirect_uri=${url}&response_type=code&scope=snsapi_base#wechat_redirect`
        } else {
         const {resultCode,resultMsg,data} = await httpService.get(`/api/wechat/${wx_code}`)
         if(resultCode === "000000") {
          this.appId = data.openid
          // 这里还需要先请求一个获取appid的接口
          const res = await  httpService.post("/api/staff/get_token",{open_id:this.appId})
          if (res.code === 0) {
              this.$store.dispatch("setToken", res.data.token)
              this.$store.dispatch("setUserInfo", res.data)
          } else if(res.code === -4){
              this.show =  true
          } 
         } else {
            Toast(resultMsg)
         }
         
        }
      }
   
    },
     isIos() {
        let u = navigator.userAgent
        return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    },
    getUrlParam:function (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    async getInit() {
      try {
        // this.$store.commit("showLoading");
        this.$store.commit("setBaseOptions");
        const res = await httpService.post("/api/staff/get_stats", {});
        if (res.code === 0) {
          this.formData = res.data || {};
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.$store.commit("hideLoading");
      }
    },
    handleRouterTo(name, type) {
      let path = "";
      switch (name) {
        case "ryxx":
          path = "personInfo";
          break;
        case "fwxx":
          path = "houseInfo";
          break;
        case "yhd":
          path = "dangerInfo";
          break;
        case "sjsb":
          path = "eventReporting";
          break;
        case "pfrw":
          path = "dispatchTasks";
          break;
        case "zfrw":
          path = "visitingTasks";
          break;
        case "kqdk":
          path = "attendanceClock";
          break;
      }
      
      this.$router.push({
        path: path,
        query: {
          type:type != undefined ?  type : undefined
        }
      });
    },
    // 处理登录
    async handleLogin() {
      if (!this.phone) {
        Toast("请输入手机号");
        return
      }
      var reg = /^1[3456789]\d{9}$/
      if(!reg.test(this.phone)){
        Toast("请输入正确的手机号");
        return
      }
      if(!this.pwd) {
        Toast("请输入密码");
        return
      }
    let pwd = this.phone + this.pwd;
      const res = await  httpService.post("/api/staff/get_token",{open_id:this.appId,login_name:this.phone, pwd: sha1(pwd)})
      if (res.code === 0) {
        this.show = false

        this.$store.dispatch("setToken", res.data.token)
        this.$store.dispatch("setUserInfo", res.data)
      } else if(res.code === -3){
        Toast("手机号不存在");
      } else if(res.code === -4) {
        Toast("密码不正确");
      }
    },
    // 退出功能
    handleQuit() {
      console.log(1111)
      Dialog.confirm({
        title: '提示',
        message: '是否要退出登录?',
      })
        .then(() => {
          httpService.post("/api/staff/sign_out",{login_name:this.userInfo.loginAcc}).then(res => {
              if (res.code === 0) {
                Toast("退出成功");
                this.$store.dispatch("setToken", "")
                this.$store.dispatch("setUserInfo", {})
                this.$router.replace({ path: "/login" })
              }
          }) 
        })
        .catch(() => {
          // on cancel
        });
    }
  },
};
</script>

<style lang="less">
.home-warp {
  background: rgb(248, 248, 248);
  letter-spacing: 0px;
  overflow: auto;
  .img1 {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }
  .person-box {
    position: absolute;
    top: 130px;
    left: 28px;
    > img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .home-content {
    margin: -65px auto;
    width: calc(100vw - 30px);
    border-radius: 15px;
    overflow: hidden;

    .content-top {
      padding: 12px 15px;
      /* 信息 */
      box-sizing: border-box;

      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;
      .bg-blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.24);
        /* 白色 */
        border-left: 2px solid rgb(255, 255, 255);
        border-top: 2px solid rgb(255, 255, 255);
        border-right: 2px solid rgb(255, 255, 255);
        box-shadow: inset 0px 0px 3.56px rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(89.03px);
        z-index: -1;
      }
      > div {
        width: 33.33%;
        p {
          text-align: center;
          font-size: 16px;
          color: #3a86ff;
          line-height: 24px;
          &.num {
            margin-top: 12px;
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
          }
        }
      }
    }
    .content-bottom {
      display: flex;
      flex-wrap: wrap;
      background: #fff;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      > div {
        width: 50%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 20px 0;
        &:active {
          background: rgba(181, 181, 181, 0.1);
        }
        img {
          width: 50px;
          height: 50px;
        }
        &:nth-child(2n):after {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgb(196, 196, 196);
          transform: scaleX(0.5);
        }
        &.has-bbottom:before {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
          background: rgb(196, 196, 196);
          transform: scaleY(0.5);
        }
      }
    }
  }
    .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 80vw;
    background-color: #fff;
  }
  .title-bd {
    font-size: 14px;
    text-align: center;
    padding:15px;
  }
  .btn-warp {
    padding:15px;
    display: flex;
    justify-content: flex-end;
  }
  .icon-warp {
    position: relative;
    font-size: 0;
    .icon {
      width:32px !important;
      height:32px !important;
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
    }
  }
}
</style>
